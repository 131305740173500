import React, { useState } from 'react';
import './App.css';
import CSVReader from 'react-csv-reader'
const SEPA = require("sepa");

function App() {

  const [description, setDescription] = useState("");

  const [data, setData] = useState<any[]>([]);

  const onSubmit = () => {
    // data.shift();
    while (data.length > 0) {
      let data2 = data.splice(0, 99);
      console.log(data2.length);
      let doc = new SEPA.Document('pain.001.001.03');
      SEPA.enableValidations(false);
      doc.grpHdr.id = "XMPL.20200801.TR0";
      doc.grpHdr.created = new Date();
      doc.grpHdr.initiatorName = "Bedrent B.V.";

      let info = doc.createPaymentInfo();
      info.requestedExecutionDate = new Date();
      info.debtorIBAN = "NL42KNAB0257412026";
      info.debtorBIC = "NOTPROVIDED";
      info.debtorName = "Bedrent B.V.";
      info.debtorId = "DE98ZZZ09999999999";
      doc.addPaymentInfo(info);


      data2.map((row, i) => {
        let tx = info.createTransaction();
        tx.creditorName = `${row[2]} ${row[3]}`;
        tx.creditorIBAN = row[0];
        tx.amount = parseFloat(row[1]);
        tx.remittanceInfo = description;
        info.addTransaction(tx);
        return null;
      })

      const element = document.createElement("a");
      const documentString = doc.toString().replace('<?xml version="1.0" encoding="null"?>', '<?xml version="1.0" encoding="UTF-8"?>').replace('0.00', '0.01');
      const file = new Blob([documentString], { type: 'text/xml' });
      element.href = URL.createObjectURL(file);
      element.download = "pain.001.001.03.xml";
      document.body.appendChild(element);
      element.click();
    }
  }

  return (
    <div className="app">
      <img alt="logo" src="/logo.jpg" />
      <p>
        Verstuur uw reclame boodschap via IbanAds. Deze boodschap heeft direct de hoogste aandacht bij de ontvanger. IbanAds brengt u zeer effectief new business. High attention value quaranteed !
      </p>
      <h1>SEPA XML Generator</h1>
      <h2>Omschrijving</h2>
      <textarea value={description} onChange={(event) => setDescription(event.target.value)} maxLength={140} placeholder="Maximaal 140 tekens" />
      <h2>CSV bestand</h2>
      <p><a href="/example.csv" target="_blank">Download hier de voorbeeld .csv</a></p>
      <CSVReader onFileLoaded={(data) => setData(data)} />
      <br />
      <button onClick={onSubmit}>Download SEPA XML</button>
      <p>IbanAds.com is een product van Golderake Invest BV te Gouderak. © 2020 All right reserved. Meer informatie of adverteren via IbabAds ? Bel 085-8769361 of e-mail info@golderake.com</p>
    </div>
  );
}

export default App;
